<template>
  <component
    :is="componentToRender"
    class="AppButton"
    :class="{
      'is-default': variant === 'default',
      'is-black': variant === 'black',
      'is-outline': variant === 'outline',
      'disable-hover': disableHover,
      'opacity-50 pointer-events-none': disabled,
      'cursor-wait pointer-events-none': loading,
      'tracking-widest': $i18n.locale === 'en',
      'is-danger': variant === 'danger',
    }"
    :disabled="disabled"
    v-bind="$attrs"
    :aria-label="ariaLabel"
  >
    <Spinner
      v-if="loading"
      class="w-6 h-6 mx-auto"
      :class="{
        'text-white': inverted,
      }"
    />
    <slot v-else />

    <div v-if="withArrow" class="AppButton__ArrowContainer">
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 72 17" class="AppButton__Arrow">
        <g fill="none" fill-rule="evenodd">
          <path d="M.5 8.5H36" />
          <path d="M.5 8.5H36" transform="translate(34, 0)" />
          <path d="M63 1l7.5 7.5L63 16" />
        </g>
      </svg>
    </div>
  </component>
</template>

<script setup lang="ts">
const props = defineProps({
  as: {
    type: String,
    default: 'button',
  },
  white: {
    type: Boolean,
    default: false,
  },
  inverted: {
    type: Boolean,
    default: false,
  },
  variant: {
    type: String,
    default: 'default',
    validator: (value: string) => ['default', 'outline', 'danger', 'gray', 'black', 'secondary'].includes(value),
  },
  ariaLabel: {
    type: String,
    default: null,
  },
  withArrow: {
    type: Boolean,
    default: false,
  },
  disableHover: {
    type: Boolean,
    default: false,
  },
  disabled: {
    type: Boolean,
    default: false,
  },
  loading: {
    type: Boolean,
    default: false,
  },
  rounded: {
    type: Boolean,
    default: true,
  },
});

const isAutoImportedComponent = (name: string) => {
  return ['AppLink'].includes(name);
};

const componentToRender = computed(() => {
  if (isAutoImportedComponent(props.as)) {
    return props.as;
  }

  return props.as;
});
</script>

<style lang="postcss" scoped>
.AppButton {
  @apply px-5 text-center border border-transparent rounded-2xs flex items-center justify-center uppercase;
  transition: all 0.5s;
  height: 49px;

  &.is-default {
    @apply bg-primary-2-100 text-white;

    /* box-shadow: design-token('button.shadow.primary'); */

    &:not(.disable-hover):hover {
      @apply bg-transparent border border-primary-2-100 text-primary-2-100;
    }
  }

  &.is-black {
    @apply text-white bg-black;

    &:not(.disable-hover):hover {
      @apply bg-white text-black;
    }
  }

  &.is-outline {
    @apply border border-primary-1-04 text-black bg-transparent;

    &:not(.disable-hover):hover {
      @apply bg-black text-white;
    }
  }

  &:disabled {
    @apply opacity-50;
    pointer-events: none;
  }

  &.is-danger {
    @apply bg-red-700 text-white;

    &:not(.disable-hover):hover {
      @apply bg-red-800;
    }
  }
}

@keyframes arrow {
  100% {
    stroke-dashoffset: 0;
    stroke-dasharray: 0;
  }
}
</style>
